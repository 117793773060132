// paralax 
.parallax-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 600px;
    padding: 64px 24px;
}

.parallax-item:nth-child(2) {
    background: url(/src/images/parallax-img.webp);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 720px;
    border-radius: 40px;

    @media (max-width: 768px) {
        border-radius: 20px;
        min-height: 400px;
        background-attachment: scroll;
    }
}

.parallax-title {
    color: #FFF;
    font-size: 80px;
    font-weight: 700;
    line-height: 120px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 32px;
    }
}

.none {
    display: none;
}

.active {
    display: block;
}

.disable {
    display: none;
}

.visible {
    visibility: visible;
}

.background {
    background-color: #FFF;
}

.background-move {
    background-image: none !important;
    margin-bottom: 128px;
    padding-top: 128px !important;

    @media (max-width: 980px) {
        padding-top: 370px !important;
        margin-bottom: initial;
    }

    @media (max-width: 720px) {
        padding-top: 248px !important;
        margin-bottom: initial;
        padding-top: 150px !important;
    }
}

.scroll-top {
    padding-top: 40px;
}

.break {
    @media (max-width: 1200px) {
        display: none;
    }
}